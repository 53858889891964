import React from 'react';
import { Link } from 'react-router-dom';

const Resume = () => {
  return (
    <div className='container mx-auto p-8'>
      <div className='flex items-center'>
        {/* Left side (Image) */}
        <div className='w-1/4 pr-4'>
          <Link to='/' className=''>
            <img
              src='https://i.pinimg.com/280x280_RS/6d/0e/c5/6d0ec5f991aab5cfbf7e8e497aea5277.jpg'
              alt='Centered'
              className='max-w-32 max-h-32 border-black shadow-md sqs-block-button-element sqs-block-button-element:hover'
            />
          </Link>
        </div>

        {/* Right side (Name, Email, Place) */}
        <div className='w-3/4'>
          <h2 className='text-2xl font-semibold mb-2'>Krishna Haranath</h2>
          <p class='text-gray-600 mb-4'>Full-Stack Developer</p>
          <p className='text-gray-600 mb-2'>xxxxxx@gmail.com</p>
          <p className='text-gray-600'>Hyderabad</p>
        </div>
      </div>
      <div className='border-t border-gray-900 my-8'></div>

      <div class='max-w-auto mx-auto bg-white p-8 rounded shadow'>
        <h2 class='text-3xl font-semibold mb-2 text-gray-600'>Skills</h2>
        <ul class='list-disc pl-6 mb-4'>
          <li>React.js</li>
          <li>JavaScript (ES6+)</li>
          <li>HTML5 & CSS3</li>
          <li>Tailwind CSS</li>
          <li>Redux</li>
          <li>Git</li>
        </ul>

        <h2 class='text-3xl font-semibold mb-2 text-gray-600 mt-10'>
          Experience
        </h2>

        <div class='mb-4'>
          <h3 class='text-md font-semibold'>Senior Software Engineer</h3>
          <p class='text-gray-600'>
            ValueLabs , Hyderabad (July 2024 - Present)
          </p>
          <p class='text-gray-700'>
            Worked on developing and maintaining React applications,
            collaborated with cross-functional teams to deliver high-quality
            software.
          </p>
        </div>

        <h2 class='text-3xl font-semibold mb-2 text-gray-600 mt-10'>
          Education
        </h2>

        <div class='mb-4'>
          <h3 class='text-md font-semibold'>
            Bachelor of Information Technology
          </h3>
          <p class='text-gray-600'>
            JNTU Kakinada, GVPCOE, Vizag (Graduated June 2015)
          </p>
        </div>

        <h2 class='text-3xl font-semibold mb-2 text-gray-600 mt-10'>
          Projects
        </h2>

        <div class='mb-4'>
          <h3 class='text-md font-semibold'>E-commerce Website</h3>
          <p class='text-gray-600'>
            Developed a responsive e-commerce website using React and integrated
            with a backend API for product data.
          </p>
        </div>

        <h2 class='text-lg font-semibold mb-2'>Contact</h2>

        <p class='text-gray-600 mb-4'>Email: iamharnadapps@gmail.com</p>

        <p class='text-gray-600'>
          LinkedIn:{' '}
          <a
            href='https://www.linkedin.com/in/iamharnad'
            target='_blank'
            rel='noopener noreferrer'
          >
            linkedin.com/in/iamharnad
          </a>
        </p>
      </div>
    </div>
  );
};

export default Resume;
