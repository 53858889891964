import React from 'react';
import { Link } from 'react-router-dom';

const Projects = () => {
  return (
    <>
      <div className='flex flex-col items-center justify-center min-h-screen'>
        <div className='flex items-center justify-center mb-4'>
          <Link to='/' className=''>
            <img
              src='https://i.pinimg.com/280x280_RS/6d/0e/c5/6d0ec5f991aab5cfbf7e8e497aea5277.jpg'
              alt='Centered'
              className='max-w-32 max-h-32 border-black shadow-md sqs-block-button-element sqs-block-button-element:hover'
            />
          </Link>
        </div>
        <p className='typewriter text-center text-gray-600 sm:p-2'>
          Here are my Projects!
        </p>
        <div className='flex flex-col sm:flex-row sm:space-x-10 mt-10 '>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0 '>
            <Link to='/' className=''>
              React
            </Link>
          </button>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0 '>
            <Link to='/javascript' className=''>
              Javascript
            </Link>
          </button>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
            <Link to='/' className=''>
              Tailwind CSS
            </Link>
          </button>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
            <Link to='/' className=''>
              BootStrap
            </Link>
          </button>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
            <Link to='/' className=''>
              Material UI
            </Link>
          </button>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
            <Link to='/' className=''>
              TypeScript
            </Link>
          </button>
        </div>
        <div className='flex flex-col sm:flex-row sm:space-x-10 mt-10 '>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
            <Link to='/' className=''>
              Graph QL
            </Link>
          </button>
          <button className='sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
            <Link to='/' className=''>
              Code Snippets
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default Projects;
