import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
// import { FaFolder } from 'react-icons/fa';
// import { BiSolidContact } from 'react-icons/bi';

const Home = () => {
  return (
    <>
      <div className='flex flex-col items-center justify-center min-h-screen mt-10'>
        <div className='flex items-center justify-center mb-4'>
          <Link to='/' className=''>
            <img
              src='https://i.pinimg.com/280x280_RS/6d/0e/c5/6d0ec5f991aab5cfbf7e8e497aea5277.jpg'
              alt='Centered'
              className='max-w-32 max-h-32 border-black shadow-md sqs-block-button-element sqs-block-button-element:hover'
            />
          </Link>
        </div>
        <p className='typewriter mx-auto text-center mt-10 max-w-md'>
          je m'appelle Krishna Haranath
        </p>
        <div className='mx-auto text-center mt-10 max-w-md'>
          Dev by day, movie buff by night. Cricket enthusiast, occasional sketch
          artist who does Urban Sketching, and a foodie coding for a living.{' '}
          <br />
          Turning bugs into features and pizza into code since 2016. <br />
        </div>

        <div className='flex flex-col sm:flex-row sm:space-x-4 mt-6 '>
          <Link to='/projects' className=''>
            <button className='btn sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
              Projects
            </button>
          </Link>
          <Link to='/resume' className=''>
            <button className='btn sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
              Resume
            </button>
          </Link>
          <Link to='/social' className=''>
            <button className='btn sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
              Social
            </button>
          </Link>
          <Link to='/contact' className=''>
            <button className='btn sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
              Contact
            </button>
          </Link>
          <Link to='/' className=''>
            <button className='btn sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
              Blog
            </button>
          </Link>
        </div>
        <div className='flex flex-col sm:flex-row sm:space-x-4 mt-6 '>
          <Link to='/' className=''>
            <button className='btn sqs-block-button-element sqs-block-button-element:hover px-6 py-3 mb-4 sm:mb-0'>
              Art
            </button>
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='bg-gray-800 text-white p-4 text-center'>
      <p>
        &copy; {currentYear} iamharnad. All rights reserved |{' '}
        <Link to='/'>Home</Link>
      </p>
    </footer>
  );
};

export default Home;
