import React from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <>
      <div className='flex flex-col items-center justify-center min-h-screen p-4'>
        <div className='flex items-center justify-center mb-4'>
          <Link to='/' className=''>
            <img
              src='https://i.pinimg.com/280x280_RS/6d/0e/c5/6d0ec5f991aab5cfbf7e8e497aea5277.jpg'
              alt='Centered'
              className='max-w-32 max-h-32 border-black shadow-md sqs-block-button-element sqs-block-button-element:hover'
            />
          </Link>
        </div>
        <p className='typewriter text-center text-gray-600 sm:p-2'>
          Alrighthy, you can Contact me here!
        </p>
        <div className='w-full max-w-lg mt-10'>
          <div class='flex flex-wrap -mx-3 mb-6'>
            <div class='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
              <label
                class='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                for='grid-first-name'
              >
                First Name
              </label>
              <input
                class='appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                id='grid-first-name'
                type='text'
                placeholder='Jane'
              />
              <p class='text-red-500 text-xs italic'>
                Please fill out this field.
              </p>
            </div>
            <div class='w-full md:w-1/2 px-3'>
              <label
                class='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                for='grid-last-name'
              >
                Last Name
              </label>
              <input
                class='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-last-name'
                type='text'
                placeholder='Doe'
              />
            </div>
          </div>
          <div class='flex flex-wrap -mx-3 mb-6'>
            <div class='w-full px-3'>
              <label
                class='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                for='grid-email'
              >
                Email
              </label>
              <input
                class='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-email'
                type='email'
                placeholder='example@example.com'
              />
              <p class='text-gray-600 text-xs italic'>
                I'll share your email with everyone else :P Just Kidding
              </p>
            </div>
          </div>

          <div class='flex flex-wrap -mx-3 mb-2'>
            <div class='w-full px-3'>
              <label
                class='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                for='grid-message'
              >
                Message
              </label>
              <textarea
                class='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-message'
                rows='4'
                placeholder='Type your message...'
              ></textarea>
              <p class='text-gray-600 text-xs italic mt-2'>
                Okay, tell me what is it?
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col sm:flex-row sm:space-x-10 mt-10 '>
          <button className='sqs-block-button-element px-8 py-3 mb-4 sm:mb-0 sm:hover:bg-gray-300 md:w-full'>
            <Link to='/blog' className='flex items-center'>
              Send
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default Contact;
