import './App.css';
import Home from './Pages/Home/Home';
import { Routes, Route } from 'react-router-dom';
import Projects from './Pages/Projects/Projects';
import Contact from './Pages/Contact/Contact';
import { Helmet } from 'react-helmet';
import Resume from './Pages/Resume/Resume';
import Social from './Pages/Social';
import JS from './Pages/Projects/Javascript/JS';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Krishna's Portfolio | Senior Software Engineer</title>
      </Helmet>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/javascript' element={<JS />} />
        <Route path='/resume' element={<Resume />} />
        <Route path='/social' element={<Social />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/' element={<Contact />} />
        <Route path='/' element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
